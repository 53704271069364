import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { ModalType } from '@movecloser/front-core';
import { EModal } from '@contract/modal';
import { Inject } from '@plugin/inversify';
import { SingleDetail } from '../SingleDetail';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
let Email = class Email extends Vue {
    /**
     * User's e-mail.
     */
    get email() {
        if (this.me === null) {
            return '';
        }
        return this.me.get('email') || this.$t('_.none');
    }
    /**
     * Handles the `@click` event on the "edit" button.
     */
    onEditBtnClick() {
        this.openInfoModal();
    }
    /**
     * User's full name.
     */
    get fullName() {
        if (this.me === null) {
            return '';
        }
        const firstName = this.me.get('firstName');
        const lastName = this.me.get('lastName');
        return `${firstName} ${lastName}`;
    }
    /**
     * User data.
     */
    get me() {
        return this.$store.getters['profile/me'];
    }
    /**
     * Opens the User's native e-mail client app.
     */
    openEmailClient() {
        const body = this.$t('modules.profile.components.account-details.email.mailto.body', { email: this.email, fullName: this.fullName });
        const subject = this.$t('modules.profile.components.account-details.email.mailto.subject');
        const to = this.$t('modules.profile.components.account-details.email.mailto.to');
        window.location.href = `mailto:${to}?subject=${subject}&body=${body}`;
    }
    /**
     * Opens the `<InfoModal>` with the appropriate payload.
     */
    openInfoModal() {
        this.modalConnector.open(EModal.Info, {
            body: this.$t('modules.profile.components.account-details.email.modal.body'),
            buttons: [{
                    label: this.$t('_.contact-us'),
                    handler: () => { this.openEmailClient(); },
                    className: 'btn-primary'
                }],
            title: this.$t('modules.profile.components.account-details.email.modal.title')
        });
    }
};
__decorate([
    Inject(ModalType)
], Email.prototype, "modalConnector", void 0);
Email = __decorate([
    Component({ name: 'Email', components: { SingleDetail } })
], Email);
export { Email };
export default Email;
