import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { SingleDetail } from '../SingleDetail';
let InvoiceData = class InvoiceData extends Vue {
    get company() {
        if (this.me === null) {
            return null;
        }
        return this.me.get('companyData');
    }
    get me() {
        return this.$store.getters['profile/me'];
    }
};
InvoiceData = __decorate([
    Component({ name: 'InvoiceData', components: { SingleDetail } })
], InvoiceData);
export { InvoiceData };
export default InvoiceData;
