import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { SingleDetail } from '../SingleDetail';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
let Password = class Password extends Vue {
};
Password = __decorate([
    Component({ name: 'Password', components: { SingleDetail } })
], Password);
export { Password };
export default Password;
