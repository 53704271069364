import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { SingleDetail } from '../SingleDetail';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
let ProfileImage = class ProfileImage extends Vue {
    constructor() {
        super(...arguments);
        this.avatar = null;
    }
    async mounted() {
        this.avatar = this.getAvatar();
    }
    /**
     * User's avatar.
     */
    getAvatar() {
        if (this.me === null || this.me.avatar === null) {
            return null;
        }
        return {
            src: this.me.avatar,
            width: null,
            height: null
        };
    }
    /**
     * User data.
     */
    get me() {
        return this.$store.getters['profile/me'];
    }
};
ProfileImage = __decorate([
    Component({ name: 'ProfileImage', components: { SingleDetail } })
], ProfileImage);
export { ProfileImage };
export default ProfileImage;
