import { __decorate } from "tslib";
import { AuthServiceType, LocalStorage } from '@movecloser/front-core';
import { Component, Vue } from 'vue-property-decorator';
import { Callout } from '@component/Callout';
import { Inject } from '@plugin/inversify';
import { localStorage } from '@support/local-storage';
import { AuthRepositoryType } from '../../../contracts';
import { InvoiceData } from '../../../components/AccountDetails/InvoiceData';
import { Email } from '../../../components/AccountDetails/Email';
import { Nickname } from '../../../components/AccountDetails/Nickname';
import { Password } from '../../../components/AccountDetails/Password';
import { ProfileImage } from '../../../components/AccountDetails/ProfileImage';
import { SiteServiceType } from '@service/site';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
let MyDataView = class MyDataView extends Vue {
    constructor() {
        super(...arguments);
        this.showWelcomeCallout = false;
    }
    get me() {
        return this.$store.getters['profile/me'];
    }
    get username() {
        var _a, _b;
        return ((_a = this.me) === null || _a === void 0 ? void 0 : _a.nickname) ? `, ${(_b = this.me) === null || _b === void 0 ? void 0 : _b.nickname}` : '';
    }
    get siteName() {
        return this.siteService.getActiveSite().name;
    }
    logOut() {
        this.authRepository.logOut().then(() => {
            this.authService.deleteToken();
            this.$store.dispatch('profile/setMe', null);
            this.$store.dispatch('profile/setSubscription', null);
            if (typeof window !== 'undefined' && typeof document !== 'undefined') {
                LocalStorage.remove('d24-user-id');
                LocalStorage.remove('d24-client-id');
                LocalStorage.remove('d24-client-sub');
            }
            this.$router.push('/');
        }).catch(error => this.$logger(error, 'error'));
    }
};
__decorate([
    Inject(AuthRepositoryType)
], MyDataView.prototype, "authRepository", void 0);
__decorate([
    Inject(AuthServiceType)
], MyDataView.prototype, "authService", void 0);
__decorate([
    Inject(SiteServiceType)
], MyDataView.prototype, "siteService", void 0);
MyDataView = __decorate([
    Component({
        name: 'MyDataView',
        components: {
            Callout,
            Email,
            InvoiceData,
            Nickname,
            Password,
            ProfileImage
        },
        beforeMount() {
            if (!localStorage.get('isTutorialCompleted')) {
                this.showWelcomeCallout = true;
            }
        }
    })
], MyDataView);
export { MyDataView };
export default MyDataView;
