import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
let SingleDetail = class SingleDetail extends Vue {
};
SingleDetail = __decorate([
    Component({ name: 'SingleDetail' })
], SingleDetail);
export { SingleDetail };
export default SingleDetail;
