import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { SingleDetail } from '../SingleDetail';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
let Nickname = class Nickname extends Vue {
    /**
     * Determine if user can fill nickname.
     */
    get canFillNickname() {
        var _a;
        return !((_a = this.me) === null || _a === void 0 ? void 0 : _a.nickname) || (!!this.subscription && this.subscription.isPaid);
    }
    /**
     * User data.
     */
    get me() {
        return this.$store.getters['profile/me'];
    }
    /**
     * User's nickname.
     */
    get nickname() {
        if (this.me === null) {
            return '';
        }
        return this.me.get('nickname') || this.$t('_.none');
    }
    get subscription() {
        return this.$store.getters['profile/subscription'];
    }
};
Nickname = __decorate([
    Component({ name: 'Nickname', components: { SingleDetail } })
], Nickname);
export { Nickname };
export default Nickname;
